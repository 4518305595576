class Tracking {
  constructor() {
    /*  https://developer.mozilla.org/en-US/docs/Web/API/Window/parent
     *  If a window does not have a parent, its parent property is a reference to itself.
     */
    try {
      window.parent.dataLayer = window.parent.dataLayer || [];
    } catch (e) {
      window.dataLayer = window.dataLayer || [];
    }

    const initMenuClick = this.initMenuClick.bind(this),
          initStageTracking = this.initStageTracking.bind(this),
          initTileTracking = this.initTileTracking.bind(this),
          initLinkTracking = this.initLinkTracking.bind(this),
          initJumpLinkTracking = this.initJumpLinkTracking.bind(this);
          
      
    window.setTimeout(() => {
      initMenuClick();
      initStageTracking();
      initTileTracking();
      initLinkTracking();
      initJumpLinkTracking();
    }, 500);

    document.addEventListener('scroll', (event) => {
      initTileTracking();
    });
  }

  initMenuClick() {
    const links = document.querySelectorAll('header nav > div > a');
    links.forEach((link) => {
      this.initComponentInteraction(link, 'Header Sprungmarke', 'navigation');
    });
  }

  initStageTracking() {
    const selector = '.tariff a.js-add-to-cart';
    const link = document.querySelector(selector);
    this.initVisibility(selector, .5, {
      'event': 'component_visible',
      'Komponente': 'Angebotsbühne',
      'Kontext': link.href
    });
    this.initComponentInteraction(link, 'Angebotsbühne');
  }

  initTileTracking() {
    const links = document.querySelectorAll('.js-tariff-container a.js-add-to-cart');
    links.forEach((link, index) => {
      this.initVisibility(`.js-tariff-container:nth-of-type(${index+1}) a.js-add-to-cart`, .5, {
          'event': 'component_visible',
          'Komponente': 'Angebotskachel',
          'Kontext': link.href
      });
      this.initComponentInteraction(link, 'Angebotskachel');
    });
  }

  initLinkTracking() {
    const links = document.querySelectorAll('.js-tariff-container a.js-component-interaction');
    links.forEach((link) => {
      this.initComponentInteraction(link, 'Angebotskachel', 'information');
    });
  }

  initJumpLinkTracking() {
    const links = document.querySelectorAll('#memorygroups ol a.js-component-interaction');
    links.forEach((link) => {
      this.initComponentInteraction(link, 'Angebotskachel', link.textContent, 'mobile Sprungmarke');
    });
  }

  initComponentInteraction(link, component, contextInput = false, actionInput = false) {
    const context = contextInput ? contextInput : link.href;
    const action = actionInput ? actionInput : link.textContent;
    link.onclick = (() => {
      this.track({
        'event': 'component_interaction',
        'Komponente': component,
        'Aktion': action,
        'Kontext': context
      });
    }).bind(this);
  }

  initVisibility(selector, percent, event) {
    if (!this.alreadyTracked(event)) {
      const domNode = document.querySelector(selector);
      if (!domNode) {
        return false;
      }
      if (this.isInViewport(domNode, percent)) {
        this.track(event);
        return true;
      }
    }
    return false;
  }

  alreadyTracked(event) {
    if (window.dataLayer && window.dataLayer.find(e => e.event === event.event && e.Komponente === event.Komponente && e.Kontext === event.Kontext)) {
      return true;
    }
    return false;
  }

  isInViewport(elem, percent) {
    const bounding = elem.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight),
    windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    const halfHeight = bounding.height * percent,
    halfWidth = bounding.width * percent;
    return (
      bounding.top + halfHeight >= 0 &&
      bounding.left + halfWidth >= 0 &&
      bounding.top + halfHeight <= windowHeight &&
      bounding.left + halfWidth <= windowWidth
    );
  }

  track(event) {
    window.dataLayer.push(event);
  }
}

window.Tracking = new Tracking();
